import * as React from "react"
import qs from "query-string"
import { navigate } from "gatsby"

import SEO from "../../components/seo"
import Spinner from "../../components/ui/LoadingSpinner"
import { useStore } from "../../context/NewStoreContext"
import POSCheckout from "../../components/v2/checkout/POSCheckout"
import POSCheckoutAdyen from "../../components/v2/checkout/POSCheckoutAdyen"
import { hasAdyenEnabled } from "../../components/v2/utils/cart/hasAdyenEnabled"

const PaymentLink = () => {
  const [isLoading, setLoading] = React.useState(true)
  const store = useStore()

  React.useEffect(() => {
    if (!isLoading) {
      return
    }

    let { pct } = qs.parse(window.location.search)
    if (!pct) {
      navigate("/")
    }

    if (store.cart?.id) {
      setLoading(false)
    }
  }, [store.cart])

  return (
    <>
      <SEO title="Checkout" />
      {isLoading ? (
        <Spinner />
      ) : hasAdyenEnabled(store.cart) ? (
        <POSCheckoutAdyen />
      ) : (
        <POSCheckout />
      )}
    </>
  )
}

export default PaymentLink
